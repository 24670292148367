<template>
    <v-container class="my-2  text-left pt-5">
        <v-btn
            color="green"
            dark
            medium
            @click="showSendMailPopup = !showSendMailPopup"
        >
            Wyślij wiadomość zbiorczą
        </v-btn>

        <v-dialog
            v-model="showSendMailPopup"
            max-width="1000px"
        >
            <v-card>
                <v-card-title>
                    <span>Wyślij wiadomość zbiorczą</span>
                    <span 
                        @click="showSendMailPopup = !showSendMailPopup" 
                        class="ml-auto cursor-pointer"
                    >
                    X
                    </span>
                </v-card-title>

                <v-card-text>
                    <v-form ref="form">
                        <v-text-field
                            v-model="title"
                            label="Tytuł"
                            clearable
                        ></v-text-field>
                        <VueEditor 
                            v-model="description" 
                            class='mb-4' 
                        />
                        <template v-if="isForEmployes">
                            <v-btn-toggle
                                v-model="users"
                                class="d-flex"
                            >
                                <v-btn  
                                    small 
                                >
                                    do copywriterów
                                </v-btn >
                                <v-btn 
                                    small
                                    color="blue-grey"
                                    class="ml-3  mr-3" 
                                >
                                    do korektorów
                                </v-btn>
                                <v-btn 
                                    small  
                                    color="success"
                                >
                                    do wszystkich pracowników
                                </v-btn>
                            
                            </v-btn-toggle>
                        </template>

                    </v-form>
                </v-card-text>
                <v-card-actions class="ml-4 pb-5">
                    <v-btn
                        color="primary"
                        @click="sendMails"
						:loading="isLoaded"
                    >
                        Wyślij
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
const token = localStorage.getItem('user');
// import io from 'socket.io-client';
import { VueEditor } from 'vue2-editor';
import { validationMixin } from 'vuelidate';
import {errorMsg} from '@/mixins/Validation';

export default{
    mixins: [validationMixin, errorMsg],
    components: {
        VueEditor,
	},
	computed: {
	
	},
    props: {
        isForEmployes: {
            type: Boolean
        }
    },
    name: 'SendMails',
    data(){
        return {
            title: '',
            description: '',
            users: null,
            isLoaded: false,
            showSendMailPopup: false
        }
    },

    methods: {
        choiseDestinationUsers(number){
            let user = 'copy';

            switch (number){
                case 0:
                    user = "copy"
                    break;
                case 1:
                    user = "korektor"
                    break;
                case 2:
                    user =  "all"
                    break;
                default:
                    user = "copy"
                    break;
            }
            return user;
        },
        sendMails(){
            this.isLoaded = true;
            this.$axios.post(`${this.url}api/users/send-mails`, {
                title: this.title,
                description: this.description,
                toUsers: this.isForEmployes ? this.choiseDestinationUsers(this.users) : 'praktykant'
                
            }, { headers: { 'x-access-token': token } }).then(() => {
                this.showSendMailPopup = false;
                this.title = '';
                this.description = '';
                this.users = '';
                this.isLoaded = false;
            })
        }


    },

    created(){
        // this.socket.on('newClientAdded', () => {
        // });
    }
}
</script>

<style lang="scss">
.cursor-pointer{
    cursor: pointer;
}
.editr{
    display: none !important;
}
</style>
