<template>
    <div class="popup"
		v-if="itemRole != 'administrator' && employeeId != userID"
	>
        <div class="my-4">
			<v-btn
				color="red"
				class="mt-1 mb-1 mr-1 white--text"
				fab
				small
				@click.stop="modal = true"
			>
				<v-icon dark>mdi-trash-can-outline</v-icon>
			</v-btn>
        </div>
        <v-dialog
            v-model="modal"
            max-width="500px"
        >
            <v-card>
                    <v-card-title>
                        Czy chcesz wysłać wiadomość pożegnalną?
                    </v-card-title>
                    <v-card-text>
						<v-btn
							color="red"
							class="white--text mr-4"
							@click.stop="deleteEmployee('send')"
						>
							TAK
						</v-btn>
						<v-btn
							color="gray"
							class="dark--text"
							@click.stop="deleteEmployee('dontsend')"
						>
							NIE
						</v-btn>
					</v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
const token = localStorage.getItem('user');

export default {
	props: {
		employeeId : {
			type: String
		},
		itemRole: {
			type: String
		}
	},
	data() {
		return {
			modal: false,
		}
	},
	computed: {
		userID(){
			return this.$store.getters.getUserID
		}
	
	},
	methods: {
		deleteEmployee(send){
            this.$axios.delete(`${this.url}api/users/${this.employeeId}/${send}`, { headers: { 'x-access-token': token } })
                .then(
					this.modal = false
				)
                .catch((error) => {
                    throw error
                })
        }
	},
}
</script>