<template>
    <v-container class="my-2 text-left">
        <v-btn
            color="indigo"
            dark
            medium
            @click="show = !show"
        >
            Dodaj pracownika
        </v-btn>

        <v-dialog v-model="show" max-width="1000px">
            <v-card>
                <v-toolbar class="indigo" dark flat>
                    <v-toolbar-title>Dodaj pracownika</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form ref="form">
                        <v-text-field
                            label="Imię i nazwisko"
                            name="name"
                            type="text"
                            v-model="name"
                            clearable
                            :error-messages="nameErrors"
                            @input="$v.name.$touch()"
                            @blur="$v.name.$touch()"   
                        />

                        <v-text-field
                            label="Email"
                            name="email"
                            type="text"
                            v-model="email"
                            clearable
                            :error-messages="emailErrors"
                            @input="$v.email.$touch()"
                            @blur="$v.email.$touch()" 
                            
                        />

                        <v-text-field
                            id="password"
                            label="Hasło"
                            name="password"
                            type="password"
                            v-model="password"
                            :error-messages="passwordErrors"
                            @input="$v.password.$touch()"
                            @blur="$v.password.$touch()" 
                            clearable

                        />

                        <v-text-field
                            id="phone"
                            label="Numer telefonu"
                            name="phone"
                            type="text"
                            v-model="phone"
                            clearable
                        />

                        <v-radio-group
                            row
                            v-model="role"
                        >
                            <v-radio
                                label="Copywriter"
                                value="copywriter"
                            ></v-radio>
                            <v-radio
                                label="Administrator"
                                value="administrator"
                                v-if="currentRole == 'administrator'"
                            ></v-radio>
                            <v-radio
                                label="Moderator"
                                value="moderator"
                            ></v-radio>
                            <v-radio
                                label="Korektor"
                                value="korektor"
                            ></v-radio>
                        </v-radio-group>
                        <v-combobox
                            v-model="langs"
                            :items="langsArray"
                            label="Języki"
                            multiple
                            chips
                        ></v-combobox>
                        <v-combobox
                            v-model="industries"
                            :items="industriesArray"
                            label="Branże"
                            multiple
                            chips
                        ></v-combobox>
                        
                        <p v-if="feedback">{{ feedback }}</p>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        class="ma-2"
                        :disabled="$v.$invalid"
                        color="secondary"
                        @click="add"
						:loading="isLoaded"
                    >
                        Dodaj
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
const { required, email } = require('vuelidate/lib/validators')
import { errorMsg } from '@/mixins/Validation';

const token = localStorage.getItem('user');

export default{
    name: 'AddEmployee',
    mixins: [validationMixin, errorMsg],
    computed: {
        currentRole(){
            return this.$store.getters.getUserRole;
        }
    },
    data(){
        return{
			isLoaded: false,
            type: "Osoba Fizyczna",
            loader: null,
            email: null,
            account: null,
            password: null,
			feedback: null,
			phone:null,
            sex: null,
            name: null,
            show: false,
            nip: null,
            role: "copywriter",
            langs: null,
            langsArray: ["PL", "EN", "DE", "FR", "IT", "CS", "DA", "ES", "PT", "RU", "SK", "SV", "UK", "HU", "ZH", "HR", "NL", "NB"],
            industries: null,
            industriesArray: [
                "Budownictwo",
                "Dietetyka",
                "Edukacja",
                "Erotyka",
                "Farmacja",
                "Finanse/rachunkowość",
                "Fotografia",
                "Fotowoltaika",
                "Fryzjerstwo",
                "Giełda",
                "HR",
                "IT",
                "Inwestycje",
                "Kosmetyka",
                "Kryptowaluty",
                "Lifestyle",
                "Marketing",
                "Meble",
                "Medycyna",
                "Moda",
                "Motoryzacja",
                "Nieruchomości",
                "Prawo",
                "Przemysł",
                "Psychologia",
                "Rolnictwo",
                "Sport",
                "Transport",
                "Turystyka",
                "Wystrój wnętrz",
            ],
            date: new Date().toISOString().substr(0, 7),
        
        }
    },
    validations: {
        name: {
            required,
        },
        email: {
            required,
            email
        },
        password: {
            required
        },
    },
    methods: {
		resetValidation(){
			this.$refs.form.reset()
			this.$nextTick(() => { this.$v.$reset() })
		},
        add(){
            if(!this.$v.$invalid){
				this.isLoaded = true
                this.$axios.post(`${this.url}api/users`, {
                    accountNumber: this.account,
                    password: this.password,
                    role: this.role.toLowerCase(),
                    type: this.type ,
                    email: this.email,
                    payment: this.payment,
                    phone: this.phone,
                    langs: this.langs,
                    industries: this.industries,
                    pesel: this.nip,
                    name: this.name
                }, { headers: { 'x-access-token': token } }).then(()=>{
					this.isLoaded = false;
                    this.resetValidation()
                    this.show = false
                }).catch(()=>{
					this.isLoaded = false;
				})
            }
        },

    },
}
</script>
